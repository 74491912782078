@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Ubuntu:ital,wght@0,400;0,500;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Inter:wght@400;500;600;700;800;900&display=fallback');


// $primary-color: #ffe0be;
$primary-color: #000000;
$primary-font: Montserrat;
$secondary-font: Poppins;
// $text-color: #13593b;
$text-color: #000000;
$back-color: #fff;
$secondary-color: #13593b;
$primary-color: #c70fff;

/* ======== reset ======= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/* ========== navbar =========== */
body {
  font-family: Montserrat;
  overflow-x: hidden;
}

/* ============ navbar ============= */

.nav-colored {
  background-color: #ffe0be !important;
  color: #13593b;
}

/* ============ parent ============ */

#parent {
  width: 100vw;
  min-height: 700px;
  background-color: rgb(180, 237, 255);
  background-color: #ffe0be;
  background-color: #fff;
  margin-top: 0px;
  overflow-x: hidden;
}

.layout-container {
  width: 1440px;
  // margin: 70px auto 0 auto;
  margin: 0px auto 0 auto;
  background-color: #ffccac;
  background-color: #fff;
  overflow-x: hidden;
}

/* ============ Nav container ============ */
#nav-parent {
  width: 100vw;
  // position: fixed;
  padding: 0 50px 0 50px;
  top: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-self: center;
  background-color: #fff6e2;
  // border-bottom: 1px solid #ffbb00;
  z-index: 10;

  .navbar {
    display: flex;
    height: 70px;
    width: 1440px;
    // padding: 20px 20px;
    margin: 0 auto;
    background-color: #fff6e2;
    // border-bottom: 1px solid #ffae00;
    color: #0e3a2f;
    font-weight: 700;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 10;

    .logo {
      color: $text-color;
      font-family: "Inter";
      font-size: 30px;
      font-weight: 800;
      font-size: 35px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;

      li {
        margin: 0 5px;

        a {
          color: $text-color;
          text-decoration: none;
          font-size: 1.2rem;
          font-weight: 600;
          padding: 8px 15px;
          letter-spacing: 1px;
          transition: all 0.3s ease;
        }
      }
    }

    ul li a:hover {
      color: #fff;
      background-color: $primary-color;
    }

    .menu-btn {
      display: none;
      i {
        color: $text-color;
        font-size: 2rem;
        cursor: pointer;
        display: none;
      }
    }

    input[type="checkbox"] {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #nav-parent {
    width: 100vw;
    position: static;
    padding: 0;
    top: 0;
    height: 65px;
    display: flex;
    align-items: center;
    justify-self: center;
    background-color: #fff6e2;
    border-bottom: none;
    z-index: 1;

    .navbar {
      width: 100vw;
      position: fixed;
      padding: 0 40px 0 17px;
      top: 0;
      height: 65px;
      display: flex;
      align-items: center;
      justify-self: center;
      background-color: #fff6e2;
      border-bottom: none;
      z-index: 10;

      .logo {
        color: $text-color;
        font-size: 20px;
        font-weight: 800;
        font-family: "Inter";
      }

      .menu-btn i {
        display: block;
        font-size: 1.9rem;
      }

      #click:checked ~ ul {
        right: 0;
      }

      #click:checked ~ ul li a {
        margin-right: 0px;
      }

      ul {
        position: fixed;
        top: 65px;
        right: -100%;
        background-color: $primary-color;
        height: 100vh;
        width: 100%;
        // background-image: url(../images/Report.svg);
        background-repeat: no-repeat;
        background-size: 90%;
        background-position: 156px;
        background-position-y: 200px;
        text-align: center;
        display: block;
        transition: all 0.3s ease;

        li {
          width: 100%;
          margin: 12px 0;

          a {
            width: 100%;
            text-align: left;
            margin-right: -100%;
            display: block;
            font-size: 1.4rem;
            font-weight: 700;
            padding-left: 31px;
            transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);

            &:hover {
              color: #fff;
              background-color: $text-color;
            }
          }
        }
      }
    }
  }
}

/* ============ hero container ============ */
.hero-container {
  padding: 30px 50px 0 50px;
  margin-top: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 720px;
  background-color: #ffe0be;
  background-image: url("https://ik.imagekit.io/k3m4pqzpmlr/desktop_coupons/finge.io_banner__1__LENiDtlH-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647498644663");
  background-position: center;
  background-size: contain;
  position: relative;

  .hero-box1 {
    h1 {
      font-family: "Montserrat";
      font-size: 4rem;
      line-height: 1.2;
      text-align: center;
      font-weight: 800;
      color: #000000;
      margin-bottom: 30px;

      // .gradient {
      //     background: -webkit-linear-gradient(45deg,#ff30a2, #740aff);
      //     -webkit-background-clip: text;
      //     -webkit-text-fill-color: transparent;
      // }

      /* Style the rainbow text element. */
      .gradient {
        /* Create a conic gradient. */
        /* Double percentages to avoid blur (#000 10%, #fff 10%, #fff 20%, ...). */
        background: #ff5613;
        background-color: #ff5613;
        background: conic-gradient(
          #ff30a2 36.666%,
          #ff30a2 36.666%,
          #740aff 33.333%,
          #ff30a2 33.333%,
          #740aff 50%,
          #740aff 50%,
          #a332ff 66.666%,
          #740aff 66.666%,
          #ff30a2 83.333%,
          #ff30a2 83.333%
        );

        /* Set thee background size and repeat properties. */
        background-size: 57%;
        background-repeat: repeat;

        /* Use the text as a mask for the background. */
        /* This will show the gradient as a text color rather than element bg. */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        /* Animate the text when loading the element. */
        /* This animates it on page load and when hovering out. */
        animation: rainbow-text-animation-rev 0.5s ease forwards;

        cursor: pointer;

        /* Add animation on hover. */
        &:hover {
          animation: rainbow-text-animation 0.5s ease forwards;
        }
      }

      /* Move the background and make it larger. */
      /* Animation shown when hovering over the text. */
      @keyframes rainbow-text-animation {
        0% {
          background-size: 57%;
          background-position: 0 0;
        }
        20% {
          background-size: 57%;
          background-position: 0 1em;
        }
        100% {
          background-size: 300%;
          background-position: -9em 1em;
        }
      }

      /* Move the background and make it smaller. */
      /* Animation shown when entering the page and after the hover animation. */
      @keyframes rainbow-text-animation-rev {
        0% {
          background-size: 300%;
          background-position: -9em 1em;
        }
        20% {
          background-size: 57%;
          background-position: 0 1em;
        }
        100% {
          background-size: 57%;
          background-position: 0 0;
        }
      }
    }

    .hero-btn {
      margin-top: 65px !important;
      width: 290px;
      margin: 0 auto;
      height: 50px;
      background-color: #000;
      color: #fff;
      cursor: pointer;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        border: none;
        padding: 10px 20px 10px 60px;
        font-size: 23px;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        i {
          margin-left: 10px;
        }
      }

      // &:hover {
      //     background-color: #ffff;
      // }
    }
  }
}

// ======== about section =========
.about_container {
  padding: 30px 100px;
  width: 100%;
  height: 1120px;
  background-image: url("https://ik.imagekit.io/k3m4pqzpmlr/desktop_coupons/Untitled_design__1__9SM4YXe5NU.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648217953708");
  display: grid;
  grid-template-rows: 240px 720px;
  grid-template-columns: 1fr;

  .about-box1 {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    .x1 {
      h1 {
        font-size: 28px;
        font-weight: 800;
        font-family: "Montserrat";
      }

      p {
        margin-top: 14px;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .x2 {
    }
  }

  .about-box2-grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 25px;

    .b1 {
      background-color: #fff;
      padding: 25px 25px;
      border-radius: 8px;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);

      h3 {
        margin-top: 15px;
        font-size: 21px;
        font-weight: 700;
        font-family: "Montserrat";
      }

      p {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .get-started {
      // background-color: #fff;
      padding: 25px 25px;
      border-radius: 8px;
      // box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

      h3 {
        margin-top: 43px;
        font-size: 21px;
        font-weight: 700;
        font-family: "Montserrat";
      }

      .btn {
        margin-top: 35px;
        background-color: #000;
        width: 250px;
        height: 50px;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          border: none;
          padding: 13px 55px;
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          text-decoration: none;
          transition: all 0.3s ease-in-out;

          i {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

// ======== projects section =========

.projects_container {
  padding: 100px 50px 30px 50px;
  width: 100%;
  height: 800px;
  display: grid;
  grid-template-rows: 120px 1fr;
  grid-template-columns: 1fr;
  background-image: url("https://ik.imagekit.io/k3m4pqzpmlr/desktop_coupons/finge-ban2__2__q99RR73zS.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653724233816");
  background-image: url("https://ik.imagekit.io/k3m4pqzpmlr/desktop_coupons/finge-ban2__1__ZgRxRJ2D3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653724438292");
  .projects_box1 {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;

    h3 {
      color: #000;
      font-size: 45px;
      font-weight: 700;
      font-family: $primary-font;
      text-align: center;
    }
  }

  .project-b2 {
    width: 100%;
    height: 100%;

    .in-box {
      width: 70%;
      margin: 0 auto;
      padding: 60px 0 30px 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-gap: 20px;
      place-items: center;
      overflow: hidden;
      color: #fff;

      .client {
        cursor: pointer;
        .logo1 {
          display: block;
          width: 120px;
          height: 40px;
          margin: 0 auto;
          margin-bottom: 10px;
        }
        padding: 10px 20px;
        font-size: 30px;
        font-weight: 700;
        color: grey;

        &:hover {
          color: black;
        }
      }
    }
  }
}

// ======== footer section =========
.footer_container {
  padding: 30px;
  width: 100%;
  height: 430px;
  display: grid;
  background-color: #fff6e2;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 1.3rem;
  color: #efefef;
  font-weight: 600;

  h5 {
    font-family: "League Spartan";
    padding-top: 10px;
    margin-left: -50px;
    font-size: 65px;
    font-weight: 600;
    color: #1b1b1b;
  }

  .logo {
    font-family: "Inter";
    padding-top: 10px;
    margin-left: -50px;
    font-size: 35px;
    font-weight: 600;
    color: #1b1b1b;
  }

  .more-info {
    padding-top: 10px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    color: #1b1b1b;

    .email {
      font-size: 21px;
      margin-top: 17px;
      color: #1b1b1b;
      text-decoration: underline;
    }
  }

  .footer-social {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 28px;
    place-items: center;

    a {
      text-decoration: none;
      color: #1b1b1b;
      font-size: 30px;
      i {
        color: #1b1b1b;
        font-size: 30px;
      }
    }
  }
}

.number-pop {
  width: 340px;
  height: 205px;
  border-radius: 4px;
  padding: 20px;
  position: fixed;
  bottom: 15px;
  right: 13px;
  z-index: 10;
  background-color: #fff;
  box-shadow: 1px 1px 5px 6px rgba(0, 0, 0, 0.2);

  .close-pop {
    position: absolute;
    font-size: 20px;
    color: #a5a5a5;
    top: 8px;
    right: 10px;
    cursor: pointer;
  }

  .head-pop {
    font-size: 21px;
    font-weight: 600;
    margin-top: 7px;
    color: rgb(24, 24, 24);
  }

  .form-pop {
    margin-top: 16px;

    input {
      width: 270px;
      border: 2px solid #c5c5c5;
      border-radius: 3px;
      font-family: montserrat;
      padding: 7px 7px;
      font-size: 18px;
      font-weight: 500;
      outline: none;
    }

    .btn-pop {
      width: 180px;
      background-color: #181818;
      color: #fff;
      padding: 8px 14px;
      margin-top: 21px;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

// ========== media queries =========

@media only screen and (max-width: 768px) {
  #parent {
    overflow-x: hidden;
    width: 100vw;
  }

  .layout-container {
    width: 100vw;
    // margin: 70px auto 0 auto;
    margin: 0px auto 0 auto;
    background-color: #ffccac;
    background-color: #fff;
    overflow-x: hidden;
  }

  /* ============ hero container ============ */
  .hero-container {
    padding: 10px;
    margin-top: 0;
    display: grid;
    place-items: center;
    width: 100%;
    height: 600px;
    background-color: #ffe0be;
    background-image: url("https://ik.imagekit.io/k3m4pqzpmlr/desktop_coupons/1_1qa9dWVHn.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648467673581");
    background-size: cover;
    position: relative;

    .hero-box1 {
      h1 {
        font-family: "Montserrat";
        font-size: 41px;
        line-height: 1.2;
        text-align: center;
        font-weight: 800;
        color: #000000;
        margin-bottom: 10px;

        // .gradient {
        //     background: -webkit-linear-gradient(45deg,#ff30a2, #740aff);
        //     -webkit-background-clip: text;
        //     -webkit-text-fill-color: transparent;
        // }

        /* Style the rainbow text element. */
        .gradient {
          /* Create a conic gradient. */
          /* Double percentages to avoid blur (#000 10%, #fff 10%, #fff 20%, ...). */
          background: #ff5613;
          background-color: #ff5613;
          background: conic-gradient(
            #ff30a2 36.666%,
            #ff30a2 36.666%,
            #740aff 33.333%,
            #ff30a2 33.333%,
            #740aff 50%,
            #740aff 50%,
            #a332ff 66.666%,
            #740aff 66.666%,
            #ff30a2 83.333%,
            #ff30a2 83.333%
          );

          /* Set thee background size and repeat properties. */
          background-size: 57%;
          background-repeat: repeat;

          /* Use the text as a mask for the background. */
          /* This will show the gradient as a text color rather than element bg. */
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          /* Animate the text when loading the element. */
          /* This animates it on page load and when hovering out. */
          animation: rainbow-text-animation-rev 0.5s ease forwards;

          cursor: pointer;

          /* Add animation on hover. */
          &:hover {
            animation: rainbow-text-animation 0.5s ease forwards;
          }
        }

        /* Move the background and make it larger. */
        /* Animation shown when hovering over the text. */
        @keyframes rainbow-text-animation {
          0% {
            background-size: 57%;
            background-position: 0 0;
          }
          20% {
            background-size: 57%;
            background-position: 0 1em;
          }
          100% {
            background-size: 300%;
            background-position: -9em 1em;
          }
        }

        /* Move the background and make it smaller. */
        /* Animation shown when entering the page and after the hover animation. */
        @keyframes rainbow-text-animation-rev {
          0% {
            background-size: 300%;
            background-position: -9em 1em;
          }
          20% {
            background-size: 57%;
            background-position: 0 1em;
          }
          100% {
            background-size: 57%;
            background-position: 0 0;
          }
        }
      }

      .hero-btn {
        margin-top: 45px !important;
        width: 200px;
        margin: 0 auto;
        height: 40px;
        background-color: #000;
        color: #fff;
        cursor: pointer;

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          border: none;
          padding: 8px 20px 8px 40px;
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          text-decoration: none;
          transition: all 0.3s ease-in-out;

          i {
            margin-left: 10px;
          }
        }

        // &:hover {
        //     background-color: #ffff;
        // }
      }
    }
  }

  // ======== about section =========
  .about_container {
    padding: 10px;
    margin-top: 0;
    display: grid;
    place-items: center;
    width: 100%;
    height: 2250px;
    background-color: #ffe0be;
    background-image: url("https://ik.imagekit.io/k3m4pqzpmlr/desktop_coupons/2_hitOMkhUp.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648467667898");
    background-position: center;
    background-size: cover;
    position: relative;
    display: grid;
    grid-template-rows: 250px 2000px;
    grid-template-columns: 1fr;

    .about-box1 {
      margin-top: 40px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 0px;

      .x1 {
        padding: 10px 20px;
        h1 {
          font-size: 20px;
          font-weight: 800;
          font-family: "Montserrat";
        }

        p {
          margin-top: 14px;
          font-size: 17px;
          font-weight: 500;
        }
      }

      .x2 {
        display: none;
      }
    }

    .about-box2-grid {
      margin-top: 20px;
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
      grid-gap: 23px;

      .b1 {
        background-color: #fff;
        height: 300px;
        width: 90%;
        margin: 0 auto;
        padding: 14px;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);

        h3 {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 700;
          font-family: "Montserrat";
        }

        p {
          margin-top: 10px;
          font-size: 13px;
          font-weight: 600;
        }
      }

      .get-started {
        // background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        // box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

        h3 {
          margin-top: 20px;
          font-size: 18px;
          font-weight: 700;
          font-family: "Montserrat";
        }

        .btn {
          margin-top: 35px;
          background-color: #000;
          width: 250px;
          height: 40px;
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          cursor: pointer;

          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            border: none;
            padding: 8px 55px;
            font-size: 17px;
            font-weight: 600;
            color: #fff;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            i {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  // ======== projects section =========

  // ======== projects section =========

  .projects_container {
    padding: 20px;
    width: 100%;
    height: 840px;
    display: grid;
    grid-template-rows: 120px 1fr;
    grid-template-columns: 1fr;
    background-image: url("https://ik.imagekit.io/k3m4pqzpmlr/desktop_coupons/mobile-ban-Finge_V_fuKpBy3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654028231396");

    .projects_box1 {
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;
      // display: none;
      h3 {
        color: #000;
        font-size: 38px;
        font-weight: 700;
        font-family: "league Spartan";
        margin-top: 70px;
        text-align: center;

        .btn {
          background-color: #000;
          font-size: 30px;
          font-weight: 600;
          color: #efefef;
          width: 230px;
          padding: 15px 23px;
          margin: 0 auto;
          margin-top: 34px;
          cursor: pointer;
          background: linear-gradient(45deg, rgb(111, 0, 255), #c506ff);
          background: linear-gradient(45deg, rgb(0, 0, 0), #4d4d4d);
          animation: hue-rotate 2s linear infinite alternate;

          /* Use the text as a mask for the background. */
          /* This will show the gradient as a text color rather than element bg. */
          //  -webkit-background-clip: text;
          //  -webkit-text-fill-color: transparent;

          @keyframes hue-rotate {
            to {
              filter: hue-rotate(90deg);
            }
          }
        }
      }
    }

    .project-b2 {
      width: 100%;
      height: 100%;

      .in-box {
        width: 100%;
        margin: 0 auto;
        padding: 50px 0 30px 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 10px;
        place-items: left;
        overflow: hidden;
        color: #fff;
        // border: 2px dashed grey;

        .client {
          cursor: pointer;
          .logo1 {
            display: block;
            width: 120px;
            height: 40px;
            margin: 0 auto;
            margin-bottom: 10px;
          }
          padding: 10px 20px;
          font-size: 24px;
          font-weight: 700;
          color: grey;

          @keyframes hue-rotate {
            to {
              filter: hue-rotate(180deg);
            }
          }
        }
      }
    }
  }

  // ======== footer section =========
  .footer_container {
    padding: 20px;
    width: 100%;
    height: 500px;
    display: grid;
    grid-template-rows: 80px 1fr 80px;
    grid-gap: 15px;
    grid-template-columns: 1fr;
    background-color: #fff6e2;
    place-items: center;
    padding: 30px;
    font-size: 1.3rem;
    color: #000;
    font-weight: 600;

    h5 {
      padding-top: 10px;
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      margin-left: 0px;
    }

    .more-info {
      padding-top: 10px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.3;
      color: #000;

      .email {
        font-size: 19px;
        margin-top: 17px;
        color: #000;
        text-decoration: underline;
      }
    }

    .footer-social {
      margin-top: 25px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 28px;
      place-items: center;

      a {
        text-decoration: none;
        color: #000;
        font-size: 30px;
        i {
          color: #000;
          font-size: 30px;
        }
      }
    }
  }
}
